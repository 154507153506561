import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import './assets/css/reset.css'
import './assets/css/fonts.css'
import './assets/css/variables.css'
import './assets/css/app.css'

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Accept'] = 'application/json'

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
