<template>
  <footer>
    <div class="content-wrap">
      <div class="kontakt">
        <h2>Karl Grimmer MSc</h2>
        <p>grimmer.karl@gmail.com</p>
        <p>0699 1943 49 59</p>
      </div>

      <address>
        <!-- <div class="col-1"> -->
        <h2>Praxis</h2>
        <p>Mariahilfer Straße 117/2/21</p>
        <p>1060 Wien</p>
        <!-- </div>
        <div class="col-1"> -->
        <br />
        <h2>Zweitpraxis</h2>
        <p>Wiedner Hauptstraße 33/11</p>
        <p>1040 Wien</p>
        <!-- </div> -->
      </address>

      <div class="links">
        <div class="internal">
          <router-link class="router-link" :to="{ name: 'Impressum' }">IMPRESSUM</router-link>
          <router-link class="router-link" :to="{ name: 'Datenschutz' }">DATENSCHUTZ</router-link>
        </div>
        <div class="external">
          <span>Website:&nbsp;</span>
          <a href="https://seitenumbruch.at" target="_blank">Seitenumbruch</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
footer {
  overflow: hidden;
  background: var(--col-beige-medium);
}

.content-wrap {
  width: var(--content-width);
  margin: 0 auto;
}

@media all and (min-width: 640px) {
  .content-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
  }
}

@media all and (min-width: 1080px) {
  .content-wrap {
    grid-template-columns: repeat(2, 352px) auto;
  }
}

.kontakt,
address {
  position: relative;
  padding-top: 32px;
  font-size: var(--font-size-regular);
  letter-spacing: 0;
  line-height: var(--leading-regular);
}

.links {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 32px;
  padding: 32px 0;
  z-index: 0;
}

.kontakt::before,
address::before,
.links::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 40px;
  top: 0;
  left: 0;
  background-color: var(--col-primary);
}

@media all and (min-width: 640px) {
  .kontakt,
  address {
    padding-bottom: 32px;
  }
}

@media all and (min-width: 1080px) {
  .kontakt,
  address {
    padding-bottom: 96px;
  }
}

address::before {
  display: none;
}

@media all and (min-width: 640px) {
  address::before {
    display: block;
  }
}

@media all and (min-width: 1080px) {
  .links::before {
    display: none;
  }
}

.links::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  background: var(--col-beige-dark);
  transform: translateX(-50%);
  z-index: -1;
}

@media all and (min-width: 640px) {
  .links {
    grid-column: span 2;
  }
}

@media all and (min-width: 1080px) {
  .links {
    grid-column: auto;
    margin-top: 0;
  }

  .links::after {
    display: none;
  }
}

h2 {
  font-weight: var(--weight-sans-semibold);
}

.internal {
  flex: 1 0 auto;
  display: flex;
  align-items: flex-end;
}

@media all and (min-width: 1080px) {
  .internal {
    flex-direction: column;
  }
}

.external {
  flex-grow: 0;
  margin-top: 1em;
}

@media all and (min-width: 1080px) {
  .external {
    text-align: right;
  }
}

.router-link {
  display: inline-block;
  position: relative;
  padding: 0.375em 0;
  font-size: var(--font-size-reduced);
  font-weight: var(--weight-sans-regular);
  letter-spacing: var(--tracking-sans-uppercase-double);
  z-index: 0;
}

.router-link:nth-of-type(2) {
  margin-left: 3em;
}

@media all and (min-width: 1080px) {
  .router-link:first {
    margin-top: -0.375em;
  }

  .router-link:nth-of-type(2) {
    margin-top: 0.25em;
  }
}

.external a {
  position: relative;
}

.router-link::after,
.external a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--col-black);
  transform: scaleX(0);
  transition: var(--underline-transition);
}

.has-hover .router-link:hover::after,
.has-hover .external a:hover::after {
  transform: scaleX(1);
}
</style>
