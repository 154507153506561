<template>
  <header :class="{ 'header-background-visible': headerBackground }">
    <div class="inner-wrap">
      <router-link class="logo" :class="{ 'hide-logo': hideLogo }" :to="{ name: 'Home' }">
        Karl Grimmer MSc
      </router-link>

      <menu-button class="menu-button" :menuOpen="menuOpen" @toggleMenu="toggleMenu"></menu-button>
      <menu-desktop class="menu-desktop" :hasHover="hasHover"></menu-desktop>
    </div>

    <menu-mobile class="menu-mobile" :menuOpen="menuOpen"></menu-mobile>
  </header>
</template>

<script>
import MenuDesktop from '@/components/MenuDesktop'
import MenuMobile from '@/components/MenuMobile'
import MenuButton from '@/components/MenuButton'

export default {
  name: 'Header',
  components: {
    MenuDesktop,
    MenuMobile,
    MenuButton,
  },
  props: {
    hasHover: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      scrolledDown: false,
      menuOpen: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.determineIfScrolled)
  },
  computed: {
    headerBackground: vm => vm.scrolledDown,
    hideLogo: vm => (vm.$route.name == 'Home' && !vm.scrolledDown ? true : false),
  },
  watch: {
    $route() {
      this.menuOpen = false
    },
  },
  methods: {
    determineIfScrolled() {
      // some browser apply the overall scroll to html and others to body
      const scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop)
      this.scrolledDown = !!scrollTop
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    preventScroll() {
      document.documentElement.style.overflowY = 'hidden'
      document.body.style.overflowY = 'hidden'
    },
    allowScroll() {
      document.documentElement.style.overflowY = 'initial'
      document.body.style.overflowY = 'initial'
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.determineIfScrolled)
  },
}
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 500;
  transition: background-color 250ms cubic-bezier(0.1, 0, 0.9, 1);
}

@media all and (min-width: 480px) {
  header.header-background-visible {
    background-color: var(--col-white);
  }
}

.menu-desktop {
  display: none;
}

@media all and (min-width: 1160px) {
  .menu-mobile,
  .menu-button {
    display: none;
  }

  .menu-desktop {
    display: block;
  }
}

.inner-wrap {
  position: relative;
  width: var(--header-content-width);
  height: var(--header-height);
  margin: 0 auto;
}

@media all and (min-width: 1160px) {
  .inner-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  font-family: var(--font-serif);
  font-size: 32px;
  transform: translateY(-50%);
}

@media all and (min-width: 480px) {
  .logo {
    display: inline-block;
  }
}

@media all and (min-width: 1160px) {
  .logo {
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    flex-shrink: 0;
  }
}

@media all and (min-width: 480px) {
  .logo.hide-logo {
    display: none;
  }
}

@media all and (min-width: 1160px) {
  .logo.hide-logo {
    display: inline-block;
  }
}

.menu-button {
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}
</style>
