<template>
  <div class="page">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
}
</script>

<style scoped>
.page {
  background-color: var(--col-white);
  /* set some overflow value to avoid margin-collapse of child */
  overflow: hidden;
}

.content {
  width: var(--content-width);
  margin: 64px auto 96px;
}

@media all and (min-width: 640px) {
  .content {
    margin: 96px auto 128px;
  }
}
</style>
