<template>
  <button class="menu-button" :class="{ active: menuOpen }" @click="emitToggleMenu">
    <div class="line menu-top"></div>
    <div class="line menu-middle"></div>
    <div class="line menu-bottom"></div>
  </button>
</template>

<script>
export default {
  name: 'MenuButton',
  props: {
    menuOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitToggleMenu() {
      this.$emit('toggleMenu')
    },
  },
}
</script>

<style scoped>
.menu-button {
  --button-size: 48px;
  display: grid;
  position: relative;
  place-items: center;
  width: var(--button-size);
  height: var(--button-size);
}

.menu-button .line {
  --stroke-width: 2px;
  --line-offset: 12px;
  width: 32px;
  height: var(--stroke-width);
  background-color: var(--col-black);
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.menu-button .line {
  position: absolute;
  top: calc(50% - var(--stroke-width) / 2);
}

.menu-button .line.menu-top {
  transform: translate3d(0, calc(var(--line-offset) * -1), 0);
}

.menu-button .line.menu-bottom {
  transform: translate3d(0, var(--line-offset), 0);
}

.menu-button.active .line.menu-top {
  transform: translate3d(0, 0, 0) rotate(-135deg);
}

.menu-button.active .line.menu-middle {
  transform: scaleX(0);
}

.menu-button.active .line.menu-bottom {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}
</style>
