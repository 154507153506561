<template>
  <div class="home">
    <div class="hero">
      <div class="hero-img">
        <hero-img></hero-img>
      </div>
      <div class="hero-title-box">
        <h1 class="hero-title">Karl Grimmer</h1>
        <div class="hero-subtitle">
          <span class="fachgebiet">PSYCHOTHERAPIE</span>
          <span class="line"></span>
          <span class="fachgebiet">COACHING</span>
          <span class="line"></span>
          <span class="fachgebiet">SUPERVISION</span>
        </div>
      </div>
      <div class="scroll-arrow"></div>
    </div>

    <v-page class="page">
      <section class="ueber-mich">
        <responsive-image
          class="portrait"
          :urls="{
            webp: [
              { size: 640, url: require('@/assets/img/home/portrait_640.webp') },
              { size: 960, url: require('@/assets/img/home/portrait_960.webp') },
              { size: 1280, url: require('@/assets/img/home/portrait_1280.webp') },
              { size: 1920, url: require('@/assets/img/home/portrait_1920.webp') },
            ],
            jpg: [
              { size: 640, url: require('@/assets/img/home/portrait_640.jpg') },
              { size: 960, url: require('@/assets/img/home/portrait_960.jpg') },
              { size: 1280, url: require('@/assets/img/home/portrait_1280.jpg') },
              { size: 1920, url: require('@/assets/img/home/portrait_1920.jpg') },
            ],
          }"
          sizes="(min-width: 1280px) calc(min(1120px, calc(100vw - 128px)) / 2),(min-width: 960px) calc(min(1120px, max(80vw, calc(100vw - 128px))) / 2),min(560px, max(80vw, calc(100vw - 128px)))"
          alt="Portrait: Karl Grimmer"
        ></responsive-image>
        <div class="textbox">
          <h1 class="section-title">Über Mich</h1>
          <article class="section-teaser text hyphenate">
            <p>{{ home.ueberMich }}</p>
          </article>
        </div>
      </section>

      <section class="psychotherapie">
        <h1 class="section-title">Psychotherapie</h1>
        <article class="section-teaser text hyphenate">
          <p>{{ home.psychotherapie }}</p>
        </article>

        <div class="optionen">
          <div class="spezialisierungen">
            <div class="illu">
              <responsive-image
                :urls="{
                  webp: [
                    {
                      size: 160,
                      url: require('@/assets/img/home/icons/spezialisierungen_160.webp'),
                    },
                    {
                      size: 240,
                      url: require('@/assets/img/home/icons/spezialisierungen_240.webp'),
                    },
                    {
                      size: 320,
                      url: require('@/assets/img/home/icons/spezialisierungen_320.webp'),
                    },
                  ],
                  png: [
                    {
                      size: 160,
                      url: require('@/assets/img/home/icons/spezialisierungen_160.png'),
                    },
                    {
                      size: 240,
                      url: require('@/assets/img/home/icons/spezialisierungen_240.png'),
                    },
                    {
                      size: 320,
                      url: require('@/assets/img/home/icons/spezialisierungen_320.png'),
                    },
                  ],
                }"
                sizes="160px"
                alt="Spezialisierungen"
              ></responsive-image>
            </div>
            <h2 class="option-title">SPEZIALISIERUNGEN</h2>
            <article class="option-teaser text hyphenate">
              <p>{{ home.spezialisierungen }}</p>
            </article>
            <mehr-erfahren-link
              class="mehr-erfahren-link"
              :to="{ name: 'Spezialisierungen' }"
            ></mehr-erfahren-link>
          </div>
          <div class="methoden">
            <div class="illu">
              <responsive-image
                :urls="{
                  webp: [
                    { size: 160, url: require('@/assets/img/home/icons/methoden_160.webp') },
                    { size: 240, url: require('@/assets/img/home/icons/methoden_240.webp') },
                    { size: 320, url: require('@/assets/img/home/icons/methoden_320.webp') },
                  ],
                  png: [
                    { size: 160, url: require('@/assets/img/home/icons/methoden_160.png') },
                    { size: 240, url: require('@/assets/img/home/icons/methoden_240.png') },
                    { size: 320, url: require('@/assets/img/home/icons/methoden_320.png') },
                  ],
                }"
                sizes="160px"
                alt="Methoden"
              ></responsive-image>
            </div>
            <h2 class="option-title">METHODEN</h2>
            <article class="option-teaser text hyphenate">
              <p>{{ home.methoden }}</p>
            </article>
            <mehr-erfahren-link
              class="mehr-erfahren-link"
              :to="{ name: 'Methoden' }"
            ></mehr-erfahren-link>
          </div>
          <div class="settings">
            <div class="illu">
              <responsive-image
                :urls="{
                  webp: [
                    { size: 160, url: require('@/assets/img/home/icons/settings_160.webp') },
                    { size: 240, url: require('@/assets/img/home/icons/settings_240.webp') },
                    { size: 320, url: require('@/assets/img/home/icons/settings_320.webp') },
                  ],
                  png: [
                    { size: 160, url: require('@/assets/img/home/icons/settings_160.png') },
                    { size: 240, url: require('@/assets/img/home/icons/settings_240.png') },
                    { size: 320, url: require('@/assets/img/home/icons/settings_320.png') },
                  ],
                }"
                sizes="160px"
                alt="Settings und Kosten"
              ></responsive-image>
            </div>
            <h2 class="option-title">SETTINGS UND KOSTEN</h2>
            <article class="option-teaser text hyphenate">
              <p>{{ home.settingsUndKosten }}</p>
            </article>
            <mehr-erfahren-link
              class="mehr-erfahren-link"
              :to="{ name: 'SettingsUndKosten' }"
            ></mehr-erfahren-link>
          </div>
        </div>
      </section>

      <section class="angebote">
        <h1 class="section-title">Angebote</h1>
        <article class="section-teaser text hyphenate">
          <p>{{ home.angebote }}</p>
        </article>

        <div class="optionen">
          <div class="angststoerungen">
            <div class="illu">
              <responsive-image
                :urls="{
                  webp: [
                    { size: 160, url: require('@/assets/img/home/icons/angst_160.webp') },
                    { size: 240, url: require('@/assets/img/home/icons/angst_240.webp') },
                    { size: 320, url: require('@/assets/img/home/icons/angst_320.webp') },
                  ],
                  png: [
                    { size: 160, url: require('@/assets/img/home/icons/angst_160.png') },
                    { size: 240, url: require('@/assets/img/home/icons/angst_240.png') },
                    { size: 320, url: require('@/assets/img/home/icons/angst_320.png') },
                  ],
                }"
                sizes="160px"
                alt="Angststörugnen"
              ></responsive-image>
            </div>
            <h2 class="option-title">ANGSTSTÖRUNGEN</h2>
            <article class="option-teaser text hyphenate">
              <p>{{ home.angststoerungen }}</p>
            </article>
            <mehr-erfahren-link
              class="mehr-erfahren-link"
              :to="{ name: 'Angststoerungen' }"
            ></mehr-erfahren-link>
          </div>
          <div class="selbsterfahrung">
            <div class="illu">
              <responsive-image
                :urls="{
                  webp: [
                    {
                      size: 160,
                      url: require('@/assets/img/home/icons/selbsterfahrung_160.webp'),
                    },
                    {
                      size: 240,
                      url: require('@/assets/img/home/icons/selbsterfahrung_240.webp'),
                    },
                    {
                      size: 320,
                      url: require('@/assets/img/home/icons/selbsterfahrung_320.webp'),
                    },
                  ],
                  png: [
                    {
                      size: 160,
                      url: require('@/assets/img/home/icons/selbsterfahrung_160.png'),
                    },
                    {
                      size: 240,
                      url: require('@/assets/img/home/icons/selbsterfahrung_240.png'),
                    },
                    {
                      size: 320,
                      url: require('@/assets/img/home/icons/selbsterfahrung_320.png'),
                    },
                  ],
                }"
                sizes="160px"
                alt="Selbsterfahrung"
              ></responsive-image>
            </div>
            <h2 class="option-title">SELBSTERFAHRUNG</h2>
            <article class="option-teaser text hyphenate">
              <p>{{ home.selbsterfahrung }}</p>
            </article>
            <mehr-erfahren-link
              class="mehr-erfahren-link"
              :to="{ name: 'Selbsterfahrung' }"
            ></mehr-erfahren-link>
          </div>
          <div class="supervision">
            <div class="illu">
              <responsive-image
                :urls="{
                  webp: [
                    { size: 160, url: require('@/assets/img/home/icons/supervision_160.webp') },
                    { size: 240, url: require('@/assets/img/home/icons/supervision_240.webp') },
                    { size: 320, url: require('@/assets/img/home/icons/supervision_320.webp') },
                  ],
                  png: [
                    { size: 160, url: require('@/assets/img/home/icons/supervision_160.png') },
                    { size: 240, url: require('@/assets/img/home/icons/supervision_240.png') },
                    { size: 320, url: require('@/assets/img/home/icons/supervision_320.png') },
                  ],
                }"
                sizes="160px"
                alt="Supervision und Coaching"
              ></responsive-image>
            </div>
            <h2 class="option-title">SUPERVISION UND COACHING</h2>
            <article class="option-teaser text hyphenate">
              <p>{{ home.supervisionUndCoaching }}</p>
            </article>
            <mehr-erfahren-link
              class="mehr-erfahren-link"
              :to="{ name: 'SupervisionUndCoaching' }"
            ></mehr-erfahren-link>
          </div>
        </div>
      </section>

      <section class="kontakt">
        <h1 class="section-title">Kontakt</h1>
        <div class="cols">
          <div class="info">
            <h2>KONTAKTDATEN</h2>
            <p class="email text">
              <span>Email:</span>
              <span>grimmer.karl@gmail.com</span>
            </p>
            <p class="telefon text">
              <span>Telefon:</span>
              <span>0699 1943 49 59</span>
            </p>
            <h2>PRAXISADRESSE</h2>
            <p class="text">Mariahilfer Straße 117/2/21</p>
            <p class="text">1060 Wien</p>
            <h2>ERREICHBARKEIT</h2>
            <p class="text">U3 Zieglergasse (Aufgang Webgasse)</p>
            <p class="text">U6 Westbahnhof</p>
            <h2>ZWEITPRAXIS</h2>
            <p class="text">Wiedner Hauptstraße 33/11</p>
            <p class="text">1040 Wien</p>
          </div>
          <div class="map-wrap">
            <div class="map">
              <responsive-image
                :urls="{
                  webp: [
                    { size: 640, url: require('@/assets/img/lageplan/lageplan_640.webp') },
                    { size: 800, url: require('@/assets/img/lageplan/lageplan_800.webp') },
                    { size: 960, url: require('@/assets/img/lageplan/lageplan_960.webp') },
                    { size: 1280, url: require('@/assets/img/lageplan/lageplan_1280.webp') },
                  ],
                  png: [
                    { size: 640, url: require('@/assets/img/lageplan/lageplan_640.png') },
                    { size: 800, url: require('@/assets/img/lageplan/lageplan_800.png') },
                    { size: 960, url: require('@/assets/img/lageplan/lageplan_960.png') },
                    { size: 1280, url: require('@/assets/img/lageplan/lageplan_1280.png') },
                  ],
                }"
                sizes="(min-width: 1280px) calc(min(calc(100vw - 128px), 1280px) / 2), (min-width: 960px) calc(max(80vw, calc(100vw - 128px)) / 2), (min-width: 560px) min(max(80vw, calc(100vw - 128px)), 640px), 100vw"
                alt="Supervision und Coaching"
              ></responsive-image>
            </div>
            <a
              class="google"
              href="//www.google.com/maps/place/Mariahilfer+Str.+117,+1060+Wien"
              target="_blank"
              ref="nofollow"
            >
              <span class="google-icon">
                <img :src="require('@/assets/img/google_maps.svg')" alt="Google Maps Symbol" />
              </span>
              <span class="google-text">In Google Maps ansehen</span>
            </a>
          </div>
        </div>
      </section>
    </v-page>
  </div>
</template>

<script>
import axios from 'axios'

import VPage from '@/components/Page'
import HeroImg from '@/components/HeroImg'
import ResponsiveImage from '@/components/ResponsiveImage'
import MehrErfahrenLink from '@/components/MehrErfahrenLink'

export default {
  name: 'Home',
  components: {
    VPage,
    HeroImg,
    ResponsiveImage,
    MehrErfahrenLink,
  },
  data() {
    return {
      home: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get('/home')
    next(vm => (vm.home = res.data.data))
  },
}
</script>

<style scoped>
.hero {
  display: grid;
  position: relative;
  place-items: center;
  height: 100vh;
  width: 100%;
}

.hero-img {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--col-beige-dark);
  z-index: -100;
}

.hero-title {
  --line-height: 1.75em;
  position: relative;
  margin-bottom: calc(var(--line-height) + 1em);
  font-family: var(--font-serif);
  font-size: min(72px, calc(18px + 6vw));
  letter-spacing: var(--tracking-serif);
  text-align: center;
}

@media all and (min-width: 960px) {
  .hero-title {
    margin-bottom: 0;
  }
}

.hero-title::after {
  content: '';
  position: absolute;
  height: var(--line-height);
  width: 1px;
  left: 50%;
  bottom: calc((var(--line-height) + 0.5em) * -1);
  background-color: var(--col-black);
}

@media all and (min-width: 960px) {
  .hero-title::after {
    display: none;
  }
}

.hero-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: var(--weight-sans-semibold);
  letter-spacing: var(--tracking-sans-uppercase-double);
  font-size: min(18px, calc(9px + 1.5vw));
  margin-top: 3em;
}

@media all and (min-width: 960px) {
  .hero-subtitle {
    flex-direction: row;
  }
}

.fachgebiet:not(:first-of-type) {
  margin-top: 2em;
}

@media all and (min-width: 960px) {
  .fachgebiet:not(:first-of-type) {
    margin-top: 0;
  }
}

.hero-subtitle .line {
  display: none;
  position: relative;
  height: 1px;
  width: 3em;
  margin: 0 2em;
  background-color: var(--col-black);
}

@media all and (min-width: 960px) {
  .hero-subtitle .line {
    display: inline-block;
  }
}

.scroll-arrow {
  position: absolute;
  width: 64px;
  left: 50%;
  bottom: 24px;
  transform: translate3d(-50%, 0, 0);
}

.scroll-arrow::before,
.scroll-arrow::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 50%;
  background-color: var(--col-black);
}

.scroll-arrow::before {
  transform-origin: bottom right;
  transform: rotate(20deg);
}

.scroll-arrow::after {
  left: 50%;
  transform-origin: bottom left;
  transform: rotate(-20deg);
}

.page {
  position: relative;
  z-index: 100;
}

.page >>> .content {
  margin-top: 0;
  margin-bottom: 0;
}

section {
  padding: min(128px, calc(16px + 12vw)) 0;
}

.section-title {
  position: relative;
  margin-bottom: min(3em, calc(1.25em + 9vw));
  font-family: var(--font-serif);
  font-size: min(44px, calc(11px + 6vw));
  letter-spacing: var(--tracking-serif);
  text-align: center;
}

.section-title::after {
  content: '';
  position: absolute;
  width: 72px;
  height: 1px;
  bottom: -1em;
  left: 50%;
  background-color: var(--col-primary);
  transform: translateX(-50%);
}

.section-teaser {
  max-width: 720px;
  margin: 0 auto;
  font-size: var(--font-size-increased);
}

@media all and (min-width: 1220px) {
  .optionen {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 64px;
    justify-items: start;
    margin-top: 48px;
  }
}

.optionen > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(400px, var(--content-width));
  margin: 32px auto 0;
}

@media all and (min-width: 1220px) {
  .optionen > div {
    display: block;
    width: initial;
    margin: 0;
  }
}

.illu {
  width: 160px;
  margin: 0 auto;
  transform: translateY(16px);
}

@media all and (min-width: 1220px) {
  .illu {
    margin: 0;
  }
}

.option-title {
  font-size: 16px;
  font-weight: var(--weight-sans-semibold);
  letter-spacing: var(--tracking-sans-uppercase-double);
  color: var(--col-primary);
  text-align: center;
}

@media all and (min-width: 1220px) {
  .option-title {
    text-align: left;
  }
}

.option-teaser {
  margin-top: 32px;
}

@media all and (min-width: 1220px) {
  .option-teaser {
    max-width: 320px;
  }
}

.mehr-erfahren-link {
  margin-left: auto;
  margin-right: auto;
}

.ueber-mich {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
}

@media all and (min-width: 960px) {
  .ueber-mich {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}

.portrait {
  display: block;
  max-width: 560px;
  margin: 0 auto;
}

@media all and (min-width: 960px) {
  .portrait {
    max-width: initial;
    margin: 0;
  }
}

.ueber-mich .textbox {
  margin-top: 64px;
}

@media all and (min-width: 960px) {
  .ueber-mich .textbox {
    margin-top: 0;
    margin-left: min(8vw, 128px);
  }

  .ueber-mich h1 {
    text-align: left;
  }

  .ueber-mich h1::after {
    left: 0;
    transform: translatex(0);
  }
}

.ueber-mich .section-teaser {
  text-align: justify;
}

@media all and (min-width: 960px) {
  .ueber-mich .section-teaser {
    text-align: left;
  }
}

.psychotherapie,
.kontakt {
  position: relative;
  z-index: 0;
}

.psychotherapie::before,
.kontakt::before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--col-beige-light);
  z-index: -1;
}

@media all and (min-width: 1220px) {
  .methoden .illu {
    transform: translate(-22%, 10%);
  }

  .spezialisierungen .illu {
    transform: translate(-21%, 15%);
  }

  .settings .illu {
    transform: translate(-13%, 10%);
  }

  .angststoerungen .illu {
    transform: translate(-26%, 10%);
  }

  .selbsterfahrung .illu {
    transform: translate(-20%, 5%);
  }

  .supervision .illu {
    transform: translate(-30%, 10%);
  }
}

@media all and (min-width: 960px) {
  .kontakt .cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}

.kontakt .map-wrap {
  position: relative;
  width: 100vw;
  left: 50%;
  margin: 64px auto 0;
  background-color: var(--col-white);
  transform: translateX(-50%);
}

.kontakt .map-wrap::before {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: calc(100% / 3 * 2);
}

@media all and (min-width: 560px) {
  .kontakt .map-wrap {
    width: min(100%, 640px);
    left: initial;
    transform: none;
  }
}

@media all and (min-width: 960px) {
  .kontakt .map-wrap {
    margin: auto;
    order: -1;
  }
}

.kontakt .map {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.google {
  --google-padding: 8px;
  --icon-size: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  height: calc(var(--icon-size) + 2 * var(--google-padding));
  bottom: 16px;
  right: 16px;
  padding: var(--google-padding);
  border-radius: 4px;
  background: var(--col-white);
  box-shadow: 2px 3px 15px 0 rgba(0, 0, 0, 0.075);
  overflow: hidden;
}

.google-icon {
  display: block;
  height: var(--icon-size);
  width: var(--icon-size);
  margin-right: 8px;
}

.google-icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.google-text {
  width: 0;
  margin-right: -8px;
  font-size: var(--font-size-regular);
  font-weight: var(--weight-sans-regular);
  line-height: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition: width 375ms cubic-bezier(0, 0.5, 0.5, 1), opacity 375ms linear;
}

.has-hover .map-wrap:hover .google-text {
  width: calc(12.5 * var(--font-size-regular));
  opacity: 1;
}

@media all and (min-width: 560px) {
  .kontakt .info {
    width: min(100%, 640px);
    margin-left: auto;
    margin-right: auto;
  }
}

@media all and (min-width: 960px) {
  .kontakt .info {
    margin-left: min(8vw, 128px);
    margin-right: initial;
  }
}

.kontakt h2 {
  font-size: 16px;
  font-weight: var(--weight-sans-semibold);
  letter-spacing: var(--tracking-sans-uppercase-double);
  color: var(--col-primary);
}

.kontakt h2:not(:first-of-type) {
  margin-top: 2em;
}

.kontakt h2 {
  margin-bottom: 1em;
}

.email,
.telefon {
  display: grid;
  grid-template-columns: 5em auto;
}
</style>
