<template>
  <svg viewBox="0 0 1039 676" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f)">
      <path
        class="fill"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M830.137 541H734.567C754.277 559.631 774.053 579.407 777.396 582.75C775.907 579.347 776.063 571.807 798.698 571.807C821.334 571.807 832.697 574.697 838.218 580.218L842.177 584.177C853.812 595.812 849.622 599.307 845.81 602.487C842.628 605.142 839.708 607.578 846.477 614.347L899.703 667.572H956.709L830.137 541ZM1030.94 667.572H969.241L842.669 541H904.365L1030.94 667.572ZM268.442 667.572L215.217 614.347C208.448 607.578 200.656 605.142 192.164 602.487C181.991 599.307 170.812 595.812 159.177 584.177L155.218 580.218C149.697 574.697 155.28 571.807 177.916 571.807C200.552 571.807 215.787 579.347 221.104 582.75C217.761 579.407 197.985 559.631 180.433 541H8L134.572 667.572H268.442Z"
      />
      <path
        class="fill"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M180.433 541H8V405.998H214.4V470.435L213.935 470.483C188.065 473.169 185.159 481.089 182.126 513.702C181.378 521.744 180.832 531.261 180.433 541ZM830.137 541H734.567C734.168 531.261 733.622 521.744 732.874 513.702C729.841 481.089 726.935 473.169 701.065 470.483C700.022 470.375 698.989 470.268 697.965 470.164V405.998H830.137V541ZM904.365 541H842.669V405.998H904.365V541ZM697.965 8H904.365V217.875H773.377L752 346.138H830.137V382.257H697.965V8ZM467.977 8H674.377V382.258H467.977V8ZM237.988 8H444.388V382.258H237.988V8ZM214.4 8V382.257H8V8H214.4ZM842.669 346.138V382.257H904.365V346.138H842.669ZM444.388 463.015C425.814 463.089 411.841 463.545 379.777 466.326C365.07 467.601 362.514 472.32 359.973 477.011C359.8 477.329 359.628 477.648 359.451 477.966C357.141 472.977 353.211 470.055 338.663 468.82C298.449 465.406 272.567 465.357 237.988 468.211V405.998H444.388V463.015ZM467.977 405.998H674.377V467.998C641.101 465.362 615.523 465.493 576.337 468.82C561.789 470.055 557.859 472.977 555.549 477.966C555.375 477.652 555.204 477.337 555.033 477.022L555.027 477.011C552.486 472.32 549.93 467.601 535.223 466.326C501.662 463.415 487.921 463.052 467.977 463.006V405.998Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_f"
        x="0"
        y="0"
        width="1038.94"
        height="675.572"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HeroImg',
}
</script>

<style scoped>
svg {
  position: absolute;
  height: 80vh;
  bottom: -4px;
  left: 70%;
  transform: translateX(-75%);
}

.fill {
  fill: #ffffffaa;
}
</style>
