<template>
  <div class="menu-desktop">
    <nav>
      <ul class="main-menu">
        <li>
          <router-link class="main-menu-item" :to="{ name: 'UeberMich' }">
            ÜBER MICH
          </router-link>
        </li>
        <li>
          <button
            ref="psychotherapieSubmenuButton"
            class="main-menu-item sub-menu-button"
            :class="{ active: showPsychotherapieSubmenu }"
            @click="showPsychotherapieSubmenu = !showPsychotherapieSubmenu"
            @mouseenter="hoverStartPsychotherapie"
            @mouseleave="hoverEndPsychotherapie"
          >
            <span>PSYCHOTHERAPIE</span>
            <span class="arrow"></span>
          </button>
          <transition name="sub-menu">
            <ul
              ref="psychotherapieSubmenu"
              v-if="showPsychotherapieSubmenu"
              class="sub-menu"
              @mouseenter="hoverStartPsychotherapie"
              @mouseleave="hoverEndPsychotherapie"
            >
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'Spezialisierungen' }"
                  @click.native="showPsychotherapieSubmenu = false"
                >
                  SPEZIALISIERUNGEN
                </router-link>
              </li>
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'Methoden' }"
                  @click.native="showPsychotherapieSubmenu = false"
                >
                  METHODEN
                </router-link>
              </li>
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'SettingsUndKosten' }"
                  @click.native="showPsychotherapieSubmenu = false"
                >
                  SETTINGS UND KOSTEN
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <button
            ref="angeboteSubmenuButton"
            class="main-menu-item sub-menu-button"
            :class="{ active: showAngeboteSubmenu }"
            @click="showAngeboteSubmenu = !showAngeboteSubmenu"
            @mouseenter="hoverStartAngebote"
            @mouseleave="hoverEndAngebote"
          >
            <span>ANGEBOTE</span>
            <span class="arrow"></span>
          </button>
          <transition name="sub-menu">
            <ul
              ref="angeboteSubmenu"
              v-if="showAngeboteSubmenu"
              class="sub-menu"
              @mouseenter="hoverStartAngebote"
              @mouseleave="hoverEndAngebote"
            >
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'Angststoerungen' }"
                  @click.native="showAngeboteSubmenu = false"
                >
                  ANGSTSTÖRUNGEN
                </router-link>
              </li>
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'Selbsterfahrung' }"
                  @click.native="showAngeboteSubmenu = false"
                >
                  SELBSTERFAHRUNG
                </router-link>
              </li>
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'SupervisionUndCoaching' }"
                  @click.native="showAngeboteSubmenu = false"
                >
                  SUPERVISION UND COACHING
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <router-link class="main-menu-item" :to="{ name: 'Kontakt' }">
            KONTAKT
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'MenuDesktop',
  data() {
    return {
      showPsychotherapieSubmenu: false,
      showAngeboteSubmenu: false,
    }
  },
  props: {
    hasHover: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    $route() {
      this.showPsychotherapieSubmenu = false
      this.showAngeboteSubmenu = false
    },
  },
  methods: {
    hoverStartPsychotherapie() {
      if (!this.hasHover) return
      this.showPsychotherapieSubmenu = true
    },
    hoverEndPsychotherapie() {
      if (!this.hasHover) return
      if (this.$refs.psychotherapieSubmenu?.matches(':hover')) return
      if (this.$refs.psychotherapieSubmenuButton.matches(':hover')) return
      this.showPsychotherapieSubmenu = false
    },
    hoverStartAngebote() {
      if (!this.hasHover) return
      this.showAngeboteSubmenu = true
    },
    hoverEndAngebote() {
      if (!this.hasHover) return
      if (this.$refs.angeboteSubmenu?.matches(':hover')) return
      if (this.$refs.angeboteSubmenuButton.matches(':hover')) return
      this.showAngeboteSubmenu = false
    },
  },
}
</script>

<style scoped>
.main-menu {
  display: flex;
  font-size: var(--font-size-reduced);
  font-weight: var(--weight-sans-regular);
  letter-spacing: var(--tracking-sans-uppercase-double);
}

.main-menu > li:last-of-type {
  margin-right: -2em;
}

.main-menu-item {
  display: block;
  position: relative;
  padding: 2em calc(2em - var(--tracking-sans-uppercase-double)) 2em 2em;
}

.main-menu-item::after {
  content: '';
  position: absolute;
  bottom: 1.5em;
  left: 2em;
  right: 2em;
  height: 1px;
  background-color: var(--col-black);
  transform: scaleX(0);
  transition: var(--underline-transition);
}

@keyframes underline {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.has-hover .main-menu-item:hover::after,
.main-menu-item.active::after {
  transform: scaleX(1);
}

.sub-menu-button {
  display: flex;
  position: relative;
}

.arrow {
  position: relative;
  margin-left: 1em;
  height: 0.8125em;
  width: 1.25em;
}

.arrow::before,
.arrow::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 1.5px;
  bottom: 0;
  background-color: var(--col-black);
}

.arrow::before {
  right: 50%;
  transform-origin: bottom right;
  transform: rotate(45deg);
}

.arrow::after {
  left: 50%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
}

.sub-menu {
  position: absolute;
  top: calc(100% - 8px);
  padding: 0.5em 0;
}

.sub-menu {
  background-color: var(--col-white);
  border-radius: 4px;
}

.sub-menu-item {
  display: block;
  padding: 0.75em 2em;
  line-height: 1.4em;
  white-space: no-wrap;
}

.sub-menu-enter {
  transform: translate3d(0, -16px, 0);
  opacity: 0;
}

.sub-menu-enter-active {
  transition: transform 500ms cubic-bezier(0, 0.5, 0.5, 1),
    opacity 500ms cubic-bezier(0.25, 0, 0.75, 1);
}

.sub-menu-leave-to {
  opacity: 0;
}

.sub-menu-leave-active {
  transition: opacity 250ms cubic-bezier(0.25, 0, 0.75, 1);
}
</style>
