var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"hero"},[_c('div',{staticClass:"hero-img"},[_c('hero-img')],1),_vm._m(0),_c('div',{staticClass:"scroll-arrow"})]),_c('v-page',{staticClass:"page"},[_c('section',{staticClass:"ueber-mich"},[_c('responsive-image',{staticClass:"portrait",attrs:{"urls":{
          webp: [
            { size: 640, url: require('@/assets/img/home/portrait_640.webp') },
            { size: 960, url: require('@/assets/img/home/portrait_960.webp') },
            { size: 1280, url: require('@/assets/img/home/portrait_1280.webp') },
            { size: 1920, url: require('@/assets/img/home/portrait_1920.webp') } ],
          jpg: [
            { size: 640, url: require('@/assets/img/home/portrait_640.jpg') },
            { size: 960, url: require('@/assets/img/home/portrait_960.jpg') },
            { size: 1280, url: require('@/assets/img/home/portrait_1280.jpg') },
            { size: 1920, url: require('@/assets/img/home/portrait_1920.jpg') } ],
        },"sizes":"(min-width: 1280px) calc(min(1120px, calc(100vw - 128px)) / 2),(min-width: 960px) calc(min(1120px, max(80vw, calc(100vw - 128px))) / 2),min(560px, max(80vw, calc(100vw - 128px)))","alt":"Portrait: Karl Grimmer"}}),_c('div',{staticClass:"textbox"},[_c('h1',{staticClass:"section-title"},[_vm._v("Über Mich")]),_c('article',{staticClass:"section-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.ueberMich))])])])],1),_c('section',{staticClass:"psychotherapie"},[_c('h1',{staticClass:"section-title"},[_vm._v("Psychotherapie")]),_c('article',{staticClass:"section-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.psychotherapie))])]),_c('div',{staticClass:"optionen"},[_c('div',{staticClass:"spezialisierungen"},[_c('div',{staticClass:"illu"},[_c('responsive-image',{attrs:{"urls":{
                webp: [
                  {
                    size: 160,
                    url: require('@/assets/img/home/icons/spezialisierungen_160.webp'),
                  },
                  {
                    size: 240,
                    url: require('@/assets/img/home/icons/spezialisierungen_240.webp'),
                  },
                  {
                    size: 320,
                    url: require('@/assets/img/home/icons/spezialisierungen_320.webp'),
                  } ],
                png: [
                  {
                    size: 160,
                    url: require('@/assets/img/home/icons/spezialisierungen_160.png'),
                  },
                  {
                    size: 240,
                    url: require('@/assets/img/home/icons/spezialisierungen_240.png'),
                  },
                  {
                    size: 320,
                    url: require('@/assets/img/home/icons/spezialisierungen_320.png'),
                  } ],
              },"sizes":"160px","alt":"Spezialisierungen"}})],1),_c('h2',{staticClass:"option-title"},[_vm._v("SPEZIALISIERUNGEN")]),_c('article',{staticClass:"option-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.spezialisierungen))])]),_c('mehr-erfahren-link',{staticClass:"mehr-erfahren-link",attrs:{"to":{ name: 'Spezialisierungen' }}})],1),_c('div',{staticClass:"methoden"},[_c('div',{staticClass:"illu"},[_c('responsive-image',{attrs:{"urls":{
                webp: [
                  { size: 160, url: require('@/assets/img/home/icons/methoden_160.webp') },
                  { size: 240, url: require('@/assets/img/home/icons/methoden_240.webp') },
                  { size: 320, url: require('@/assets/img/home/icons/methoden_320.webp') } ],
                png: [
                  { size: 160, url: require('@/assets/img/home/icons/methoden_160.png') },
                  { size: 240, url: require('@/assets/img/home/icons/methoden_240.png') },
                  { size: 320, url: require('@/assets/img/home/icons/methoden_320.png') } ],
              },"sizes":"160px","alt":"Methoden"}})],1),_c('h2',{staticClass:"option-title"},[_vm._v("METHODEN")]),_c('article',{staticClass:"option-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.methoden))])]),_c('mehr-erfahren-link',{staticClass:"mehr-erfahren-link",attrs:{"to":{ name: 'Methoden' }}})],1),_c('div',{staticClass:"settings"},[_c('div',{staticClass:"illu"},[_c('responsive-image',{attrs:{"urls":{
                webp: [
                  { size: 160, url: require('@/assets/img/home/icons/settings_160.webp') },
                  { size: 240, url: require('@/assets/img/home/icons/settings_240.webp') },
                  { size: 320, url: require('@/assets/img/home/icons/settings_320.webp') } ],
                png: [
                  { size: 160, url: require('@/assets/img/home/icons/settings_160.png') },
                  { size: 240, url: require('@/assets/img/home/icons/settings_240.png') },
                  { size: 320, url: require('@/assets/img/home/icons/settings_320.png') } ],
              },"sizes":"160px","alt":"Settings und Kosten"}})],1),_c('h2',{staticClass:"option-title"},[_vm._v("SETTINGS UND KOSTEN")]),_c('article',{staticClass:"option-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.settingsUndKosten))])]),_c('mehr-erfahren-link',{staticClass:"mehr-erfahren-link",attrs:{"to":{ name: 'SettingsUndKosten' }}})],1)])]),_c('section',{staticClass:"angebote"},[_c('h1',{staticClass:"section-title"},[_vm._v("Angebote")]),_c('article',{staticClass:"section-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.angebote))])]),_c('div',{staticClass:"optionen"},[_c('div',{staticClass:"angststoerungen"},[_c('div',{staticClass:"illu"},[_c('responsive-image',{attrs:{"urls":{
                webp: [
                  { size: 160, url: require('@/assets/img/home/icons/angst_160.webp') },
                  { size: 240, url: require('@/assets/img/home/icons/angst_240.webp') },
                  { size: 320, url: require('@/assets/img/home/icons/angst_320.webp') } ],
                png: [
                  { size: 160, url: require('@/assets/img/home/icons/angst_160.png') },
                  { size: 240, url: require('@/assets/img/home/icons/angst_240.png') },
                  { size: 320, url: require('@/assets/img/home/icons/angst_320.png') } ],
              },"sizes":"160px","alt":"Angststörugnen"}})],1),_c('h2',{staticClass:"option-title"},[_vm._v("ANGSTSTÖRUNGEN")]),_c('article',{staticClass:"option-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.angststoerungen))])]),_c('mehr-erfahren-link',{staticClass:"mehr-erfahren-link",attrs:{"to":{ name: 'Angststoerungen' }}})],1),_c('div',{staticClass:"selbsterfahrung"},[_c('div',{staticClass:"illu"},[_c('responsive-image',{attrs:{"urls":{
                webp: [
                  {
                    size: 160,
                    url: require('@/assets/img/home/icons/selbsterfahrung_160.webp'),
                  },
                  {
                    size: 240,
                    url: require('@/assets/img/home/icons/selbsterfahrung_240.webp'),
                  },
                  {
                    size: 320,
                    url: require('@/assets/img/home/icons/selbsterfahrung_320.webp'),
                  } ],
                png: [
                  {
                    size: 160,
                    url: require('@/assets/img/home/icons/selbsterfahrung_160.png'),
                  },
                  {
                    size: 240,
                    url: require('@/assets/img/home/icons/selbsterfahrung_240.png'),
                  },
                  {
                    size: 320,
                    url: require('@/assets/img/home/icons/selbsterfahrung_320.png'),
                  } ],
              },"sizes":"160px","alt":"Selbsterfahrung"}})],1),_c('h2',{staticClass:"option-title"},[_vm._v("SELBSTERFAHRUNG")]),_c('article',{staticClass:"option-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.selbsterfahrung))])]),_c('mehr-erfahren-link',{staticClass:"mehr-erfahren-link",attrs:{"to":{ name: 'Selbsterfahrung' }}})],1),_c('div',{staticClass:"supervision"},[_c('div',{staticClass:"illu"},[_c('responsive-image',{attrs:{"urls":{
                webp: [
                  { size: 160, url: require('@/assets/img/home/icons/supervision_160.webp') },
                  { size: 240, url: require('@/assets/img/home/icons/supervision_240.webp') },
                  { size: 320, url: require('@/assets/img/home/icons/supervision_320.webp') } ],
                png: [
                  { size: 160, url: require('@/assets/img/home/icons/supervision_160.png') },
                  { size: 240, url: require('@/assets/img/home/icons/supervision_240.png') },
                  { size: 320, url: require('@/assets/img/home/icons/supervision_320.png') } ],
              },"sizes":"160px","alt":"Supervision und Coaching"}})],1),_c('h2',{staticClass:"option-title"},[_vm._v("SUPERVISION UND COACHING")]),_c('article',{staticClass:"option-teaser text hyphenate"},[_c('p',[_vm._v(_vm._s(_vm.home.supervisionUndCoaching))])]),_c('mehr-erfahren-link',{staticClass:"mehr-erfahren-link",attrs:{"to":{ name: 'SupervisionUndCoaching' }}})],1)])]),_c('section',{staticClass:"kontakt"},[_c('h1',{staticClass:"section-title"},[_vm._v("Kontakt")]),_c('div',{staticClass:"cols"},[_c('div',{staticClass:"info"},[_c('h2',[_vm._v("KONTAKTDATEN")]),_c('p',{staticClass:"email text"},[_c('span',[_vm._v("Email:")]),_c('span',[_vm._v("grimmer.karl@gmail.com")])]),_c('p',{staticClass:"telefon text"},[_c('span',[_vm._v("Telefon:")]),_c('span',[_vm._v("0699 1943 49 59")])]),_c('h2',[_vm._v("PRAXISADRESSE")]),_c('p',{staticClass:"text"},[_vm._v("Mariahilfer Straße 117/2/21")]),_c('p',{staticClass:"text"},[_vm._v("1060 Wien")]),_c('h2',[_vm._v("ERREICHBARKEIT")]),_c('p',{staticClass:"text"},[_vm._v("U3 Zieglergasse (Aufgang Webgasse)")]),_c('p',{staticClass:"text"},[_vm._v("U6 Westbahnhof")]),_c('h2',[_vm._v("ZWEITPRAXIS")]),_c('p',{staticClass:"text"},[_vm._v("Wiedner Hauptstraße 33/11")]),_c('p',{staticClass:"text"},[_vm._v("1040 Wien")])]),_c('div',{staticClass:"map-wrap"},[_c('div',{staticClass:"map"},[_c('responsive-image',{attrs:{"urls":{
                webp: [
                  { size: 640, url: require('@/assets/img/lageplan/lageplan_640.webp') },
                  { size: 800, url: require('@/assets/img/lageplan/lageplan_800.webp') },
                  { size: 960, url: require('@/assets/img/lageplan/lageplan_960.webp') },
                  { size: 1280, url: require('@/assets/img/lageplan/lageplan_1280.webp') } ],
                png: [
                  { size: 640, url: require('@/assets/img/lageplan/lageplan_640.png') },
                  { size: 800, url: require('@/assets/img/lageplan/lageplan_800.png') },
                  { size: 960, url: require('@/assets/img/lageplan/lageplan_960.png') },
                  { size: 1280, url: require('@/assets/img/lageplan/lageplan_1280.png') } ],
              },"sizes":"(min-width: 1280px) calc(min(calc(100vw - 128px), 1280px) / 2), (min-width: 960px) calc(max(80vw, calc(100vw - 128px)) / 2), (min-width: 560px) min(max(80vw, calc(100vw - 128px)), 640px), 100vw","alt":"Supervision und Coaching"}})],1),_c('a',{ref:"nofollow",staticClass:"google",attrs:{"href":"//www.google.com/maps/place/Mariahilfer+Str.+117,+1060+Wien","target":"_blank"}},[_c('span',{staticClass:"google-icon"},[_c('img',{attrs:{"src":require('@/assets/img/google_maps.svg'),"alt":"Google Maps Symbol"}})]),_c('span',{staticClass:"google-text"},[_vm._v("In Google Maps ansehen")])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero-title-box"},[_c('h1',{staticClass:"hero-title"},[_vm._v("Karl Grimmer")]),_c('div',{staticClass:"hero-subtitle"},[_c('span',{staticClass:"fachgebiet"},[_vm._v("PSYCHOTHERAPIE")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"fachgebiet"},[_vm._v("COACHING")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"fachgebiet"},[_vm._v("SUPERVISION")])])])}]

export { render, staticRenderFns }