<template>
  <picture>
    <source
      v-for="{ type, srcset } in srcsets"
      :key="type"
      :srcset="srcset"
      :sizes="sizes"
      :type="`image/${type}`"
      :media="media"
    />
    <img
      class="responsive-image"
      src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
      :alt="alt"
      :style="css"
    />
  </picture>
</template>

<script>
export default {
  name: 'ResponsiveImage',
  props: {
    urls: {
      type: Object,
      required: true,
    },
    sizes: {
      type: String,
      required: true,
    },
    media: {
      // media queries to avoid loading images when they are not needed
      type: String,
      default: '',
    },
    alt: {
      type: String,
      required: true,
    },
    css: {
      type: String,
      default: '',
    },
  },
  computed: {
    srcsets() {
      return Object.entries(this.urls).map(([type, urls]) => ({
        type: type === 'jpg' ? 'jpeg' : type,
        srcset: urls.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', ''),
      }))
    },
  },
}
</script>

<style scoped>
.responsive-image {
  display: block;
  position: relative;
}
</style>
