<template>
  <div class="menu-mobile">
    <transition name="menu" appear>
      <nav v-if="menuOpen">
        <ul class="main-menu">
          <li>
            <router-link class="main-menu-item link" :to="{ name: 'Home' }">
              HOME
            </router-link>
          </li>
          <li>
            <router-link class="main-menu-item link" :to="{ name: 'UeberMich' }">
              ÜBER MICH
            </router-link>
          </li>
          <li>
            <div class="main-menu-item">PSYCHOTHERAPIE:</div>
            <ul class="sub-menu">
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'Spezialisierungen' }"
                  @click.native="showPsychotherapieSubmenu = false"
                >
                  SPEZIALISIERUNGEN
                </router-link>
              </li>
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'Methoden' }"
                  @click.native="showPsychotherapieSubmenu = false"
                >
                  METHODEN
                </router-link>
              </li>
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'SettingsUndKosten' }"
                  @click.native="showPsychotherapieSubmenu = false"
                >
                  SETTINGS UND KOSTEN
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <div class="main-menu-item">ANGEBOTE:</div>
            <ul class="sub-menu">
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'Angststoerungen' }"
                  @click.native="showAngeboteSubmenu = false"
                >
                  ANGSTSTÖRUNGEN
                </router-link>
              </li>
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'Selbsterfahrung' }"
                  @click.native="showAngeboteSubmenu = false"
                >
                  SELBSTERFAHRUNG
                </router-link>
              </li>
              <li>
                <router-link
                  class="sub-menu-item"
                  :to="{ name: 'SupervisionUndCoaching' }"
                  @click.native="showAngeboteSubmenu = false"
                >
                  SUPERVISION UND COACHING
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link class="main-menu-item link" :to="{ name: 'Kontakt' }">
              KONTAKT
            </router-link>
          </li>
        </ul>
      </nav>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MenuMobile',
  props: {
    menuOpen: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped>
nav {
  display: grid;
  place-items: center;
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--col-white);
}

.main-menu > li,
.sub-menu > li {
  text-align: center;
}

.main-menu-item,
.sub-menu-item {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  letter-spacing: var(--tracking-sans-uppercase-double);
}

.main-menu-item {
  font-size: 3vh;
  font-weight: var(--weight-sans-regular);
  padding: 0.875em calc(2em - var(--tracking-sans-uppercase-double)) 0.857em 2em;
}

.main-menu-item.link::before,
.sub-menu-item::before {
  content: '';
  position: absolute;
  left: 2em;
  right: 2em;
  height: 1px;
  background-color: var(--col-black);
  transform-origin: center;
  transform: scaleX(0);
  transition: var(--underline-transition);
}

.main-menu-item.link::before {
  bottom: 0.5em;
}

.has-hover .main-menu-item.link:hover::before,
.has-hover .sub-menu-item:hover::before {
  transform: scaleX(1);
}

.sub-menu {
  margin-bottom: 2em;
}

.sub-menu-item {
  font-weight: var(--weight-sans-light);
  font-size: 2.25vh;
  padding: 0.75em calc(2em - var(--tracking-sans-uppercase-double)) 0.75em 2em;
}

.sub-menu-item::before {
  bottom: 0.25em;
}

.menu-enter,
.menu-leave-to {
  opacity: 0;
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 500ms cubic-bezier(0.25, 0, 0.75, 1);
}

.menu-enter .main-menu-item,
.menu-enter .sub-menu-item,
.menu-leave-to .main-menu-item,
.menu-leave-to .sub-menu-item {
  letter-spacing: 0.48em;
}

.menu-enter-active .main-menu-item,
.menu-enter-active .sub-menu-item {
  transition: letter-spacing 500ms cubic-bezier(0, 0, 0.75, 1);
}

.menu-leave-active .main-menu-item,
.menu-leave-active .sub-menu-item {
  transition: letter-spacing 500ms cubic-bezier(0.25, 0, 1, 1);
}
</style>
