<template>
  <router-link class="mehr-erfahren" :to="to">
    <slot>MEHR ERFAHREN</slot>
  </router-link>
</template>

<script>
export default {
  name: 'MehrErfahrenLink',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
}
</script>

<style scoped>
.mehr-erfahren {
  display: inline-block;
  position: relative;
  margin-top: calc(32px - 0.3em);
  padding: 0.3em calc(var(--tracking-sans-uppercase-double) * -1) 0.3em 0;
  font-size: 14px;
  font-family: var(--font-sans);
  font-weight: var(--weight-sans-regular);
  color: var(--col-primary);
  line-height: 1.2em;
  letter-spacing: var(--tracking-sans-uppercase-double);
}

.mehr-erfahren::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: var(--col-primary);
  transition: transform 250ms cubic-bezier(0, 0.5, 0.5, 1);
}

.has-hover .mehr-erfahren:hover::before {
  transform: scale(1.1, 1);
}

.mehr-erfahren::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: var(--col-primary);
  opacity: 0.1;
  transform: scale(1, 0);
  transform-origin: bottom center;
  transition: transform 250ms cubic-bezier(0, 0.5, 0.5, 1);
}

.has-hover .mehr-erfahren:hover::after {
  transform: scale(1.1, 1);
}
</style>
