<template>
  <div id="app">
    <main>
      <router-view />
    </main>
    <v-footer></v-footer>

    <v-header :hasHover="hasHover"></v-header>
  </div>
</template>

<script>
import VHeader from '@/components/Header'
import VFooter from '@/components/Footer'

export default {
  components: {
    VHeader,
    VFooter,
  },
  data() {
    return {
      hasHover: false,
    }
  },
  created() {
    this.watchForHover()
  },
  methods: {
    watchForHover() {
      let lastTouchTime = 0

      const enableHover = () => {
        if (new Date() - lastTouchTime < 500) return
        document.body.classList.add('has-hover')
        this.hasHover = true
      }
      const disableHover = () => {
        document.body.classList.remove('has-hover')
        this.hasHover = false
      }
      const updateLastTouchTime = () => {
        lastTouchTime = new Date()
      }

      document.addEventListener('touchstart', updateLastTouchTime, true)
      document.addEventListener('touchstart', disableHover, true)
      document.addEventListener('mousemove', enableHover, true)

      enableHover()
    },
  },
}
</script>
