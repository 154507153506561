import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ueber-mich',
    name: 'UeberMich',
    component: () => import(/* webpackChunkName: "ueberMich" */ '../views/UeberMich.vue'),
  },
  {
    path: '/methoden',
    name: 'Methoden',
    component: () => import(/* webpackChunkName: "methoden" */ '../views/Methoden.vue'),
  },
  {
    path: '/spezialisierungen',
    name: 'Spezialisierungen',
    component: () =>
      import(/* webpackChunkName: "spezialisierungen" */ '../views/Spezialisierungen.vue'),
  },
  {
    path: '/settings-und-kosten',
    name: 'SettingsUndKosten',
    component: () =>
      import(/* webpackChunkName: "settingsUndKosten" */ '../views/SettingsUndKosten.vue'),
  },
  {
    path: '/angststoerungen',
    name: 'Angststoerungen',
    component: () =>
      import(/* webpackChunkName: "angstStoerungen" */ '../views/Angststoerungen.vue'),
  },
  {
    path: '/selbsterfahrung',
    name: 'Selbsterfahrung',
    component: () =>
      import(/* webpackChunkName: "selbsterfahrung" */ '../views/Selbsterfahrung.vue'),
  },
  {
    path: '/supervision-und-coaching',
    name: 'SupervisionUndCoaching',
    component: () =>
      import(
        /* webpackChunkName: "supervisionUndCoaching" */ '../views/SupervisionUndCoaching.vue'
      ),
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "kontakt" */ '../views/Kontakt.vue'),
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue'),
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "datenschutz" */ '../views/Datenschutz.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
